<template>
  <v-card max-width="374" class="elevation-2">
    <v-skeleton-loader
      type="card-heading, list-item-two-line, list-item-two-line, divider, chip, list-item-avatar-two-line, list-item-avatar-two-line"
      class="mx-auto"
    ></v-skeleton-loader>
  </v-card>
</template>

<script>
export default {
  name: "InterviewCardSkeleton"
};
</script> 