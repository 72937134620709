<template>
  <v-hover v-slot:default="{ hover }" open-delay="150">
    <v-card
      :elevation="hover ? 16 : 2"
      max-width="374"
      router
      :to="{
        name: 'scheduled.interviews',
        params: { id: interview.id },
      }"
    >
      <v-system-bar window color="white">
        <v-spacer></v-spacer>
        <v-btn tile x-small dense :color="`${status.color} white--text`">
          {{ status.text }}
        </v-btn>
      </v-system-bar>

      <v-list-item two-line class="pt-2">
        <v-list-item-content>
          <v-list-item-subtitle class="caption">{{
            $t("Job_client")
          }}</v-list-item-subtitle>
          <v-list-item-title class="subtitle-2">
            {{ interview.vacancy.client ? interview.vacancy.client.name : "" }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item two-line class="pt-2">
        <v-list-item-content>
          <v-list-item-subtitle class="caption">{{
            $t("vacancy_title")
          }}</v-list-item-subtitle>
          <v-list-item-title class="subtitle-2">
            {{ interview.vacancy.title }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="mx-4"></v-divider>

      <v-card-text>
        <v-row class="px-3">
          <v-chip-group active-class="amber darken-1 white--text" column>
            <v-chip small>
              {{ formatDate() }}
            </v-chip>
            <v-chip color="primary" small>
              {{ totalCandidates }} {{ $t("candidates") }}
            </v-chip>
          </v-chip-group>
        </v-row>

        <div class="caption mt-4">{{ $t("interviewers") }}</div>
        <div class="mb-4">
          <avatar-group
            :items="avatarGroups(recrutersAndObservers.recruters)"
          />
        </div>

        <div class="caption">{{ $t("Guests") }}</div>
        <div class="whirelab-observer-div mb-4">
          <avatar-group
            :items="avatarGroups(recrutersAndObservers.observers)"
          />
        </div>
      </v-card-text>
    </v-card>
  </v-hover>
</template>

<script>
import { API_URL } from "@/api";
import { format } from "date-fns";
import AvatarGroup from "./recruter/AvatarGroup.vue";
export default {
  name: "InterviewCard",

  components: {
    AvatarGroup,
  },

  props: {
    interview: { type: Object },
    loading: { type: Boolean, default: false }
  },

  data: () => ({
    apiUrl: API_URL,
  }),

  computed: {
    totalCandidates() {
      return this.interview.candidates.length;
    },
    recrutersAndObservers() {
      const TOTAL_CANDIDATES = this.interview.candidates.length;
      const CANDIDATES = this.interview.candidates;
      let candidateIndex = 0;
      let recruters = [];
      let observers = [];

      for (
        candidateIndex;
        candidateIndex < TOTAL_CANDIDATES;
        candidateIndex++
      ) {
        const STAGES = CANDIDATES[candidateIndex].stages;
        const findedStage = STAGES.find(
          (findStage) =>
            findStage.name === CANDIDATES[candidateIndex].currentStage
        );
        
        if (findedStage) {
          recruters = [...recruters, ...findedStage.recruters];

          if (findedStage.observers) {
            observers = [...observers, ...findedStage.observers];
          }
        }
      }

      recruters = recruters.filter((findRecruter, index) => {
        const recruterIds = recruters.map((mapRecruter) => mapRecruter.user.id);

        return recruterIds.indexOf(findRecruter.user.id) === index;
      });
      observers = observers.filter((findObserver, index) => {
        const observerIds = observers.map((mapObserver) => mapObserver.user.id);

        return observerIds.indexOf(findObserver.user.id) === index;
      });

      return { recruters, observers };
    },
    status() {
      let states = [];
      const CANDIDATES = this.interview.candidates;
      CANDIDATES.forEach((mapCandidate) => {
        if (mapCandidate.status !== "pending") {
          states.push(mapCandidate.status);
        } else {
          mapCandidate.stages.forEach((mapStage) =>
            states.push(mapStage.state)
          );
        }
      });

      const STATUS = states;
      const TOTAL_STATUS = STATUS.length;
      let statusIndex = 0;
      let pending = 0,
        running = 0,
        canceled = 0,
        ongoing = 0,
        completed = 0;
      const PENDING_STATUS = { text: this.$t("pending"), color: "cyan" };
      const ONGOING_STATUS = { text: this.$t("ongoing"), color: "warning" };
      const CANCELED_STATUS = { text: this.$t("canceled"), color: "error" };
      const FINISHED_STATUS = { text: this.$t("completed"), color: "success" };

      for (statusIndex; statusIndex < TOTAL_STATUS; statusIndex++) {
        switch (STATUS[statusIndex]) {
          case "pending":
            pending += 1;
            break;

          case "rejected":
          case "approved":
            completed += 1;
            break;

          case "canceled":
            canceled += 1;
            break;

          case "ongoing":
          case "running":
          case "pause":
          case "finished":
            ongoing += 1;
            break;

          default:
            running += 1;
            break;
        }
      }

      if (TOTAL_STATUS === pending) {
        return PENDING_STATUS;
      } else if (TOTAL_STATUS === ongoing || ongoing >= 1 || running >= 1) {
        return ONGOING_STATUS;
      } else if (TOTAL_STATUS === canceled) {
        return CANCELED_STATUS;
      } else if (
        TOTAL_STATUS === completed ||
        completed + canceled === TOTAL_STATUS
      ) {
        return FINISHED_STATUS;
      }

      return PENDING_STATUS;
    },
  },

  methods: {
    recruterName(name) {
      return name.split(" ")[0];
    },
    formatDate() {
      const FIRST_ELEMENT = 0;
      const currentLang = localStorage.getItem("lang")
        ? localStorage.getItem("lang")
        : "pt";
      
      if (!this.interview.candidates || 
          !this.interview.candidates[FIRST_ELEMENT] || 
          !this.interview.candidates[FIRST_ELEMENT].stages || 
          !this.interview.candidates[FIRST_ELEMENT].stages[FIRST_ELEMENT] ||
          !this.interview.candidates[FIRST_ELEMENT].stages[FIRST_ELEMENT].date) {
        return "-";
      }
      
      const DATE_OF_FIRST_INTERVIEW = new Date(
        this.interview.candidates[FIRST_ELEMENT].stages[FIRST_ELEMENT].date
      );
      let dateAndTime;

      switch (currentLang) {
        case "en":
          dateAndTime = format(DATE_OF_FIRST_INTERVIEW, "yyyy/MM/dd");
          break;

        default:
          dateAndTime = format(DATE_OF_FIRST_INTERVIEW, "dd/MM/yyyy");
          break;
      }

      return dateAndTime;
    },

    avatarGroups(recruters) {
      return recruters.map((mapRecruter) => ({
        id: mapRecruter.user.id,
        name: mapRecruter.user.name,
        photo: mapRecruter.user.photo,
      }));
    },
  },
};
</script>

<style scoped>
.whirelab-observer-div {
  height: 20px;
}
</style>
