<template>
  <v-container class="fill-heigt" fluid>
    <v-row class="d-flex flex-row justify-space-between align-center">
      <div class="text-left black--text">
        {{ $t("InterviewManager") }}
      </div>
      <div class="d-flex justify-space-between align-center text-right">
        <filter-interview @change="changeFilter" />
        <form class="ml-4" v-on:submit.prevent="search()">
          <v-text-field
            style="text-decoration: captalize"
            v-model="text"
            append-icon="mdi-magnify"
            :label="$t('search')"
            single-line
            hide-details
            dense
            outlined
            @input="debounceSearch"
          ></v-text-field>
        </form>
      </div>
    </v-row>
    <v-row>
      <v-divider class="my-3"></v-divider>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" class="px-0">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              color="primary"
              v-show="viewGrid"
              @click="changeView(false)"
              ><v-icon>format_list_bullet</v-icon></v-btn
            >
          </template>
          <span>{{ $t("show_table") }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              color="primary"
              v-show="!viewGrid"
              @click="changeView(true)"
              ><v-icon>grid_view</v-icon></v-btn
            >
          </template>
          <span>{{ $t("show_cards") }}</span>
        </v-tooltip>
      </v-col>
      <v-col cols="12" md="6" class="text-right px-0">
        <template>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="primary"
                @click="openScheduleInterviewDialog()"
                style="margin-left: 5px"
              >
                <v-icon left>mdi-calendar</v-icon>{{ $t("schedule_interview") }}
              </v-btn>
            </template>
            <div v-html="$t('tooltip_interview')"></div>
          </v-tooltip>
        </template>
      </v-col>
    </v-row>

    <v-row v-if="loadingData && viewGrid">
      <v-col v-for="i in 8" :key="i" cols="12" md="3">
        <interview-card-skeleton />
      </v-col>
    </v-row>

    <v-row v-else-if="viewGrid">
      <v-col v-for="interview in filteredInterview" 
             :key="interview.id"
             cols="12" 
             md="3">
        <interview-card
          v-if="interview.id != '65a7b83bbc917107cf193512'"
          :interview="interview"
        />
      </v-col>
    </v-row>

    <v-row v-show="!viewGrid" class="px-0">
      <v-col cols="12" class="px-0">
        <interview-table :interviews="filteredInterview" :loading="loadingData" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="text-center">
        <v-pagination
          v-model="pagination.page"
          :length="pagination.pages"
          :total-visible="7"
          @input="changePage"
        ></v-pagination>
      </v-col>
    </v-row>

    <success-dialog />
    <schedule-interview />
  </v-container>
</template>

<script>
import { GET_INTERVIEWS_QUERY } from "../graphql/Query.gql";
import {
  INTERVIEW_SCHEDULE_SUBSCRIPTION,
  UPDATE_INTERVIEW_SCHEDULE_SUBSCRIPTION,
} from "../graphql/Subscraption.gql";
import { VUE_VIEWS } from "@/constants/settings";
import replaceSpecialCharsMixins from "@/mixins/replaceSpecialChars";
import InterviewCard from "../components/InterviewCard.vue";
import accessLevelMixins from "@/mixins/access-level";
import ScheduleInterview from "../components/ScheduleInterview";
import InterviewCardSkeleton from "../components/InterviewCardSkeleton.vue";
import FilterInterview from "../components/FilterInterview.vue";
import InterviewTable from "../components/InterviewTable.vue";
import SuccessDialog from "../components/SuccessDialog.vue";

export default {
  name: "Interview",

  components: {
    InterviewCard,
    ScheduleInterview,
    InterviewCardSkeleton,
    FilterInterview,
    InterviewTable,
    SuccessDialog,
  },

  mixins: [accessLevelMixins, replaceSpecialCharsMixins],

  data: () => ({
    text: "",
    interviews: [],
    loadingData: true,
    filterStatus: [],
    viewGrid: false,
    pagination: {
      page: 1,
      limit: 10,
      total: 0,
      pages: 0
    },
    searchTimeout: null,
  }),

  computed: {
    filteredInterview() {
      let list = this.interviews;

      if (this.filterStatus.length > 0) {
        list = list.filter((filterList) =>
          this.filterStatus.includes(filterList.sortBy)
        );
      }

      return list.filter((l) => l.id != "65a7b83bbc917107cf193512");
    },
  },

  apollo: {
    interviews: {
      query: GET_INTERVIEWS_QUERY,
      variables() {
        return {
          page: this.pagination.page,
          limit: this.pagination.limit,
          search: this.text || undefined,
          status: this.filterStatus.length > 0 ? this.filterStatus : undefined
        };
      },
      fetchPolicy: "no-cache",
      result({ data, loading }) {
        if (!loading && data.interviews) {
          this.loadingData = false;
          this.pagination = data.interviews.pagination;
          this.interviews = data.interviews.data;
          return {
            interviews: data.interviews.data
          };
        } else {
          this.loadingData = true;
        }
      },
    },
    $subscribe: {
      interviewSchedule: {
        query: INTERVIEW_SCHEDULE_SUBSCRIPTION,
        result({ data }) {
          this.interviews.unshift(data.interviewSchedule);
        },
      },
      updateInterviewSchedule: {
        query: UPDATE_INTERVIEW_SCHEDULE_SUBSCRIPTION,
        result({ data }) {
          const index = this.interviews.findIndex(
            (findInterview) =>
              findInterview.id == data.updateInterviewSchedule.id
          );
          this.interviews.splice(index, 1, data.updateInterviewSchedule);
        },
      },
    },
  },

  created() {
    this.viewGrid = JSON.parse(localStorage.getItem(VUE_VIEWS));
  },

  methods: {
    changeView(value) {
      this.viewGrid = value;
      localStorage.setItem(VUE_VIEWS, value);
    },

    openScheduleInterviewDialog() {
      // eslint-disable-next-line no-undef
      Fire.$emit("ScheduleUnAccountInterview");
    },

    changeFilter(filterStatus) {
      this.filterStatus = filterStatus;
      this.pagination.page = 1;
      this.loadingData = true;
    },

    candidates(interview) {
      return interview.candidates.map((mapCandidate) => {
        return mapCandidate.interviewee
          ? this.replaceSpecialChars(mapCandidate.interviewee.fullName)
          : mapCandidate.email;
      });
    },

    recrutersAndObservers(interview) {
      const TOTAL_CANDIDATES = interview.candidates.length;
      const CANDIDATES = interview.candidates;
      let candidateIndex = 0;
      let recruters = [];
      let observers = [];

      for (
        candidateIndex;
        candidateIndex < TOTAL_CANDIDATES;
        candidateIndex++
      ) {
        const STAGES = CANDIDATES[candidateIndex].stages;
        const findedStage = STAGES.find(
          (findStage) =>
            findStage.name === CANDIDATES[candidateIndex].currentStage
        );
        if (findedStage) recruters = [...recruters, ...findedStage.recruters];

        if (findedStage && findedStage.observers) {
          observers = [...observers, ...findedStage.observers];
        }
      }

      recruters = recruters.filter((findRecruter, index) => {
        const recruterIds = recruters.map((mapRecruter) => mapRecruter.user.id);

        return recruterIds.indexOf(findRecruter.user.id) === index;
      });
      observers = observers.filter((findObserver, index) => {
        const observerIds = observers.map((mapObserver) => mapObserver.user.id);

        return observerIds.indexOf(findObserver.user.id) === index;
      });

      recruters = recruters.map((mapRecruter) =>
        this.replaceSpecialChars(mapRecruter.user.name)
      );
      observers = observers.map((mapObserver) =>
        this.replaceSpecialChars(mapObserver.user.name)
      );

      recruters = [...recruters, ...observers];

      return recruters;
    },

    status(interview) {
      let states = [];
      const CANDIDATES = interview.candidates;
      CANDIDATES.forEach((mapCandidate) => {
        if (mapCandidate.status !== "pending") {
          states.push(mapCandidate.status);
        } else {
          mapCandidate.stages.forEach((mapStage) =>
            states.push(mapStage.state)
          );
        }
      });

      const STATUS = states;
      const TOTAL_STATUS = STATUS.length;
      let statusIndex = 0;
      let pending = 0,
        running = 0,
        canceled = 0,
        ongoing = 0,
        completed = 0;

      for (statusIndex; statusIndex < TOTAL_STATUS; statusIndex++) {
        switch (STATUS[statusIndex]) {
          case "pending":
            pending += 1;
            break;

          case "rejected":
          case "approved":
            completed += 1;
            break;

          case "canceled":
            canceled += 1;
            break;

          case "ongoing":
          case "running":
          case "pause":
          case "finished":
            ongoing += 1;
            break;

          default:
            running += 1;
            break;
        }
      }

      if (TOTAL_STATUS === pending) {
        return 1;
      } else if (TOTAL_STATUS === ongoing || ongoing >= 1 || running >= 1) {
        return 2;
      } else if (TOTAL_STATUS === canceled) {
        return 4;
      } else if (
        TOTAL_STATUS === completed ||
        completed + canceled === TOTAL_STATUS
      ) {
        return 3;
      }

      return 1;
    },

    search() {
      this.pagination.page = 1;
      this.loadingData = true;
    },

    debounceSearch() {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      
      this.searchTimeout = setTimeout(() => {
        this.search();
      }, 500);
    },

    changePage(newPage) {
      this.pagination.page = newPage;
      this.loadingData = true;
    }
  },
};
</script>
