<template>
  <v-menu
    :close-on-content-click="false"
    :nudge-width="200"
    offset-x
    bottom
    origin="center center"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on }">
      <a class="filtro" color="#000" dark v-on="on">
        <v-icon left color="primary">mdi-filter-variant</v-icon>
        <span style="color: #000">{{ $t("filter") }}</span>
      </a>
    </template>

    <v-card class="px-3 py-3">
      <v-container fluid>
        <v-card flat max-width="550">
          <v-row>
            <v-col class="12" md="12">
              <template v-for="item in items">
                <v-checkbox
                  :key="item.value"
                  v-model="filterStatus"
                  :label="item.text"
                  :value="item.value"
                  class="mr-2"
                  hide-details
                  @change="change()"
                ></v-checkbox>
              </template>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: "FilterInterview",

  data: () => ({
    filterStatus: ['pending', 'ongoing'],
  }),

  computed: {
    items() {
      return [
        { text: this.$t("pending_interviews"), value: 'pending' },
        { text: this.$t("ongoing_interviews"), value: 'ongoing' },
        { text: this.$t("completed_interviews"), value: 'approved' },
        { text: this.$t("canceled_interviews"), value: 'canceled' },
      ];
    },
  },

  methods: {
    change() {
      this.$emit("change", this.filterStatus);
    },
  },
};
</script>